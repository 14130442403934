/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef, useState } from 'react'

export interface DropdownProps {
  children?: React.ReactNode
  menu?: React.ReactNode
}

const Dropdown = ({ children, menu }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const itemClass = 'ms-1 ms-lg-3'
  const menuClass = 'symbol-35px symbol-md-40px '

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (menuRef.current != null && !menuRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return (
    <div className={`app-navbar-item dropdown dropdown-end ml-4 ${itemClass}`} ref={menuRef}>
      <div
        aria-hidden='true'
        className={`symbol cursor-pointer ${menuClass}${isOpen ? 'show menu-dropdown' : ''}`}
        onClick={toggleDropDown}
      >
        {children}
      </div>
      {isOpen && (
        <div
          className='menu-compact show menu rounded-box visible mt-3 w-52 bg-base-100 opacity-100 shadow'
          style={{
            zIndex: 105,
            position: 'absolute',
            // inset: '0px auto auto 0px',
            inset: '0px 0px auto auto',
            // inset: 'auto auto 0px 0px',
            margin: '0px',
            transform: 'translate(0px, 40px)',
            // transform: 'translate(3px, -40px)',
            // transform: 'translate(-30px, 70px)',
          }}
        >
          <ul tabIndex={0} className=''>
            {menu}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Dropdown
