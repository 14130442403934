/* eslint-disable @typescript-eslint/ban-types */
import classNames from 'classnames'
import * as React from 'react'
import { ConfigContext } from './context'
import SkeletonImage from './Image'
import SkeletonNode from './Node'
import type { SkeletonParagraphProps } from './Paragraph'
import Paragraph from './Paragraph'
import type { SkeletonTitleProps } from './Title'
import Title from './Title'
import { StyleskeletonClsLoading } from './style'

/* This only for skeleton internal. */
export interface SkeletonProps {
  active?: boolean
  loading?: boolean
  prefixCls?: string
  className?: string
  rootClassName?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  title?: SkeletonTitleProps | boolean
  paragraph?: SkeletonParagraphProps | boolean
  round?: boolean
}

function getComponentProps<T>(prop?: T | boolean): T | {} {
  if (prop && typeof prop === 'object') {
    return prop
  }
  return {}
}

function getTitleBasicProps(hasParagraph: boolean): SkeletonTitleProps {
  if (hasParagraph) {
    return { width: '38%' }
  }

  if (hasParagraph) {
    return { width: '50%' }
  }

  return {}
}

function getParagraphBasicProps(hasTitle: boolean): SkeletonParagraphProps {
  const basicProps: SkeletonParagraphProps = {}

  // Width
  if (!hasTitle) {
    basicProps.width = '61%'
  }

  // Rows
  if (hasTitle) {
    basicProps.rows = 3
  } else {
    basicProps.rows = 2
  }

  return basicProps
}

interface CompoundedComponent {
  Image: typeof SkeletonImage
  Node: typeof SkeletonNode
}

const Skeleton: React.FC<SkeletonProps> & CompoundedComponent = (props) => {
  const {
    loading,
    className,
    rootClassName,
    style,
    children,
    title = true,
    paragraph = true,
    active,
    round,
  } = props
  const { skeleton } = React.useContext(ConfigContext)
  const prefixCls = 'skeleton'

  if (loading ?? !('loading' in props)) {
    const hasTitle = !!title
    const hasParagraph = !!paragraph

    let contentNode: React.ReactNode
    if (hasTitle ?? hasParagraph) {
      // Title
      let $title: React.ReactNode
      if (hasTitle) {
        const titleProps: SkeletonTitleProps = {
          prefixCls: `${prefixCls}-title`,
          ...getTitleBasicProps(hasParagraph),
          ...getComponentProps(title),
        }

        $title = <Title {...titleProps} />
      }

      // Paragraph
      let paragraphNode: React.ReactNode
      if (hasParagraph) {
        const paragraphProps: SkeletonParagraphProps = {
          prefixCls: `${prefixCls}-paragraph`,
          ...getParagraphBasicProps(hasTitle),
          ...getComponentProps(paragraph),
        }

        paragraphNode = <Paragraph {...paragraphProps} />
      }

      contentNode = (
        <div className={`${prefixCls}-content`}>
          {$title}
          {paragraphNode}
        </div>
      )
    }

    const cls = classNames(
      prefixCls,
      {
        [`${prefixCls}-active`]: active,
        [`${prefixCls}-round`]: round,
      },
      skeleton?.className,
      className,
      rootClassName,
    )

    return (
      <StyleskeletonClsLoading className={cls} style={{ ...skeleton?.style, ...style }}>
        {contentNode}
      </StyleskeletonClsLoading>
    )
  }
  return typeof children !== 'undefined' ? (children as React.ReactElement) : null
}

Skeleton.Image = SkeletonImage
Skeleton.Node = SkeletonNode

export default Skeleton
